export interface NothingDecision {
    type: 'nothing'
    body: string
}

export interface InvestmentDecision {
    type: 'investment'
    body: string
}

export interface TermDepositDecision {
    type: 'term-deposit'
    rate: number
    matureSteps: number
}

export interface LoanSharkDecision {
    type: 'loan-shark'
    rate: number
}

export interface SchoolDecision {
    type: 'school'
}

export type Decision = InvestmentDecision | TermDepositDecision | NothingDecision | SchoolDecision | LoanSharkDecision

export const investmentDecisions: Decision[] = [
    {
        type: 'investment',
        body: '<instrument> seems to be going great guns at the moment. How much would you like to invest?',
    },
    {type: 'investment', body: '<instrument> is blasting off into outer space. How much would you like to invest'},
    {
        type: 'investment',
        body:
            '<instrument> has just shat itself, not unlike your young child. Maybe it’s a good time to invest. How much would you like to put in',
    },
    {type: 'investment', body: 'You hear news that <instrument> might be a ponzi scheme. Would you like to invest'},
    {
        type: 'investment',
        body: 'News on the market is that <instrument> may be about to be taken over. Would you like to invest',
    },
    {type: 'investment', body: '<instrument> you’ve never heard of available now. What would your closest friend do'},
    {type: 'investment', body: 'Rumours of <instrument> about to be merged. Would you like to invest'},
    {type: 'investment', body: '<instrument> revenue projections are rosy. Would you like to invest'},
    {
        type: 'investment',
        body: 'Exports are looking good, particularly affecting <instrument>. Would you like to invest',
    },
    {
        type: 'investment',
        body:
            'Party Hardy says <instrument> is going to be at $90 by Christmas. Which Christmas you’re not sure. Would you like to invest',
    },
]
