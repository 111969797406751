import React from 'react'
import {withStore, actions} from './store'

export const SplashScreen: React.FC = () => {
    const {dispatch} = withStore()

    return (
        <div className="splash">
            <h1>InvestQuest™</h1>
            <button
                onClick={() => {
                    dispatch(actions.play())
                }}
            >
                PLAY NOW!
            </button>
        </div>
    )
}
