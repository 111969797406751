import React from 'react'
import {withStore, State, actions} from './store'

export const Inventory: React.FC = () => {
    const {state, dispatch} = withStore()

    return (
        <div className="inventory">
            <h2>Inventory</h2>
            {state.inventory.length === 0 ? 'You have no items yet' : null}
            <ul>
                {state.inventory.map((item, index) => (
                    <li key={index}>{item.name}</li>
                ))}
            </ul>
        </div>
    )
}
