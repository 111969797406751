export interface Instrument {
    name: string
    priceHistory: number[]
}
export const instruments: {[symbol: string]: Instrument} = {
    HTF: {
        name: 'High Times Fund',
        priceHistory: [
            1.0124164278892074,
            0.9952830188679245,
            1.0033175355450237,
            0.985829003306566,
            0.9978437949209391,
            0.9855942376950781,
            1.0034104750304504,
            1.0303471716436028,
            0.9964655984919888,
            1.0078032631827853,
            0.9809948381041765,
            1.0004783544606553,
            1.0480516375806839,
            0.9965784671532846,
            1.0032043945983062,
            1.0047912388774811,
            1.0052225249772933,
            0.9963858143212108,
            0.9925187032418954,
            1.0100502512562812,
            0.997964721845319,
            1.0215273056877407,
            1.0008873114463175,
            0.9937943262411348,
            0.9763603925066905,
            0.9968021927820923,
            1.014665444546288,
            0.9941282746160796,
            1.0193094048159927,
            1.024738132382438,
            1.0080469769464984,
            0.9997842502696873,
            0.9978420371169614,
            1.0067041522491351,
            1.0264232008592908,
            0.9822101297614064,
            1.0095887492009377,
            0.9983115238497257,
            1.0124735729386891,
            1.0029233660471915,
            0.9929210909848012,
            1.0213881316837912,
            0.99774173680969,
            1.0162551440329217,
            0.9941283660660053,
            0.9934826883910387,
            1.0061500615006151,
            0.9906275468622656,
            1.0088440970793913,
            1.0034658511722732,
            0.9833401056481106,
            1.006611570247934,
            0.998152709359606,
            1.0039070532593048,
            0.9944694797214259,
            0.9932028836251287,
            0.9850684363334716,
            1.0237894736842106,
            0.98437178696278,
            1.0160852308335073,
            0.99609375,
            1.0024767801857586,
            1.0185299567634343,
            0.98524358196887,
            1.0038982355354944,
            1.0069487022276722,
            0.9782829307895272,
            1.0,
            1.0014522821576763,
            1.0203024652993578,
            1.0105583756345178,
            1.0036166365280288,
            1.0134134134134134,
            1.0077044646384827,
            0.9927465202901392,
            1.002567140600316,
            1.0088635020681505,
            1.0144474814525575,
            0.9953810623556582,
            0.9914926527455531,
            1.000390015600624,
            1.0033138401559454,
            0.9965028171750533,
            0.9896666016767401,
            1.0019700551615447,
            1.0053086905230044,
            0.9908077449638176,
            0.9857876036320569,
            0.9989987985582699,
            0.9929845660452996,
            0.9979814291481631,
            0.983616504854369,
            1.0026732469668929,
            0.9846185397867104,
            1.0187460945636326,
            1.0,
            1.0008178286648945,
            1.004902962206333,
            0.9936979060784713,
            0.9993862520458265,
            1.0143295803480041,
            1.012310797174571,
            1.0167464114832536,
            1.0023529411764707,
            0.9939358372456965,
            0.9978350718362526,
            0.9972386587771203,
            0.9833860759493671,
            1.0144810941271116,
            1.0114988104678828,
            0.987063896511172,
            0.9976171564733917,
            0.987062101910828,
            0.9927404718693286,
            1.0018281535648996,
            0.9890510948905109,
            1.0,
            1.0082000820008201,
            1.013216754778365,
            1.0206702789484248,
            0.9874164372788046,
            1.0254878534448426,
            0.9899029126213591,
            1.0033346410357002,
            1.0166177908113392,
            0.9805769230769231,
            1.0333398705628554,
            0.9979123173277662,
            0.9906808672499048,
            1.0053753119600692,
            1.0126026350964292,
            0.9871770695832548,
            1.0017191977077362,
            1.0083905415713197,
            1.0030257186081695,
            0.9973604826546003,
            0.9992438563327032,
            0.9880817253121453,
            0.9911928010721807,
            1.0028974309445626,
            1.0065485362095532,
            0.9908151549942594,
            1.0102356122054847,
            0.996367807302619,
            0.999424405218726,
            0.9869456709541179,
            1.0083641314919276,
            1.005401234567901,
            1.0063315425940138,
            1.0041944709246902,
            0.9810138598822858,
            1.0,
            1.0104509386491194,
            1.0007661367554108,
            1.005933014354067,
            0.986111111111111,
            0.9791626471155702,
            0.9875862068965516,
            0.9682761372705507,
            1.0055635689264373,
            1.01577868852459,
            1.0044381682469237,
            0.9895561357702349,
            1.0054800081185307,
            0.9979814291481631,
            0.977953074433657,
            0.9993795243019649,
            1.002069536423841,
            0.9991738950846758,
            0.9909053327821413,
            1.0,
            0.994785148101794,
            0.9880478087649401,
            1.0070033955857387,
            1.0136986301369861,
            1.0064449064449066,
            1.01838463127453,
            1.0044624746450306,
            1.0,
            1.0040387722132473,
            1.005028157683025,
            1.0086051630978587,
            0.998015873015873,
            0.998807157057654,
            1.0115445859872612,
            0.9980322707595435,
            1.0029574132492114,
            1.0326321997247887,
            0.9952408147725109,
            0.999043611323642,
            0.9929159486884932,
            1.0134978789047435,
            1.000570776255708,
            0.9927742916904354,
            0.9846772648917832,
            0.9885236335343318,
            1.0003935458480913,
            1.0163257277734068,
            1.0,
            0.9798722663053996,
            0.9774837053130555,
            0.996564962618711,
            1.0024330900243308,
            1.0068770226537216,
            0.9937725994375252,
            0.9957550030321407,
            1.0095412099066179,
            1.0170922984114217,
            0.98932384341637,
            0.9992006394884093,
            0.9934,
            0.9905375478155829,
            0.998170731707317,
            1.0063123600081452,
            1.0058680696074465,
            1.0032186682760007,
            1.0052135552436334,
            1.0123678436066228,
            0.9970443349753694,
            0.9952569169960475,
            0.9980142970611597,
            1.0175089534421011,
            1.0,
            1.0158388736800938,
            0.9949951876804618,
            1.004449603404914,
            0.9940292758089367,
            0.9974811083123426,
            0.9840714840714841,
            0.9940781681800236,
            1.016084193804607,
            0.9968731678717999,
            1.002940599882376,
            0.9861219702892886,
            0.9966303270564915,
            1.0079554494828957,
            1.0122336227308604,
            1.0001949317738792,
            1.0097446891444162,
            0.9893842887473461,
            1.002145922746781,
            0.9896826941794822,
            0.9826907946498822,
            0.9829863891112889,
            1.00916310323763,
            0.9985875706214689,
            1.0103051121438675,
            1.0036,
            1.0075727381426864,
            1.0029667721518987,
        ],
    },
    FBL: {
        name: 'Frisky Business',
        priceHistory: [
            1.0,
            1.0119857197324182,
            1.0016919662428758,
            1.008445541643017,
            0.9949751129073819,
            1.0101005281990119,
            1.0099995276876261,
            0.9933996486047738,
            0.9966778972569116,
            1.0,
            1.0099995276876261,
            0.9834991215119347,
            1.0050333177103499,
            1.0,
            1.0,
            1.001669370100027,
            1.006666351791751,
            0.9834444854050067,
            1.0067336854660078,
            1.0083608077826596,
            1.004974890565835,
            1.0,
            1.0,
            1.0099005270928392,
            0.9967321774679004,
            1.0131141448263503,
            1.0,
            1.0032360975545846,
            1.0096802418591286,
            1.009584199651425,
            1.0110754172498504,
            0.9984351291335241,
            1.0156732352539921,
            1.003086275134556,
            0.9876928826121483,
            1.0,
            0.9937697650734796,
            1.0015673235253992,
            1.0093892251988557,
            0.9860471684790284,
            0.993710992078269,
            0.990506785214414,
            0.9936073000300719,
            1.0,
            1.0064338295116537,
            1.0015973666085707,
            0.9936207236088109,
            1.0032101164154565,
            1.0047997668129482,
            1.0031845593994464,
            1.0,
            1.0031744501743056,
            1.0031644049285287,
            1.0015772115283308,
            0.9952758164517621,
            0.9984177975357357,
            0.9968305804079824,
            1.003179496751314,
            0.9984152902039913,
            1.006348900348611,
            1.0015772115283308,
            0.9984252721505874,
            0.9968455769433385,
            0.9968355950714713,
            0.9904766494770832,
            1.0032049722484437,
            0.9904125668129303,
            1.0,
            1.0290341959032825,
            1.0015673235253992,
            0.9953053874005721,
            0.9968554960391345,
            1.0,
            1.0,
            1.0031544230566614,
            0.9968554960391345,
            1.0047316345849922,
            0.995290648346166,
            1.0047316345849922,
            0.995290648346166,
            1.0031544230566614,
            1.0015722519804329,
            0.9937208644615546,
            1.001579703054199,
            0.9936911538866771,
            0.9872989862965283,
            1.0064338295116537,
            0.9936073000300719,
            0.993569424828008,
            1.009711568066137,
            0.9967917838525194,
            0.9903541372420122,
            1.0194829093251139,
            1.0,
            0.9936308812011075,
            1.0064099444968875,
            0.9984077203002769,
            0.9920226761322732,
            1.0,
            1.0032185419256578,
            0.9983975138757781,
            1.0048151746231848,
            1.0175710326942788,
            1.0047093516538341,
            1.0031248517434965,
            1.0031151174632602,
            1.004658165462312,
            1.0015455225211414,
            1.0,
            1.006172550269112,
            1.0122693672520902,
            1.0318197850055972,
            0.9603513360877449,
            0.9969420380016775,
            0.9892643036544211,
            0.9891477977059111,
            1.0125385882031936,
            0.996904170283168,
            0.9937891127169175,
            1.0093745552304896,
            0.9938083405663363,
            1.0,
            1.0031151174632602,
            0.9984472781792294,
            0.99222431742414,
            1.0031346470507985,
            1.0062497034869928,
            1.0,
            1.0,
            0.9813673381507524,
            0.9841747725183603,
            1.0144720484766436,
            1.004754129388026,
            0.9921139423583463,
            1.0079487418782846,
            0.9936911538866771,
            1.0126978006972223,
            0.9968653529492015,
            0.9889942361369705,
            1.0,
            0.9984102516243432,
            1.0,
            1.0159227969972313,
            0.9874614117968064,
            1.0126978006972223,
            1.0,
            1.0031346470507985,
            1.0078121293587412,
            0.9922484269327936,
            1.0078121293587412,
            1.0015503146134415,
            1.0,
            1.0046437445752476,
            1.0015407599626973,
            0.9938464413060742,
            1.0046437445752476,
            0.9984592400373026,
            1.006172550269112,
            0.993865316373955,
            0.9876548994617761,
            1.0062497034869928,
            1.0,
            1.0,
            0.9984472781792294,
            0.998444863484828,
            1.0046726761948903,
            1.0031006292268827,
            0.9938179099154342,
            1.0,
            1.001555136515172,
            0.9844727817922936,
            0.9716069997541593,
            0.9837669805926695,
            0.996699824302387,
            1.0033111029189985,
            0.9900994729071608,
            1.0433346603061933,
            0.984023100378647,
            0.9788970747704703,
            1.004974890565835,
            1.003300175697613,
            0.9901320389125267,
            1.0033221027430885,
            1.0,
            1.0033111029189985,
            1.006600351395226,
            0.9868858551736496,
            1.013288410972354,
            0.983607318967062,
            1.006666351791751,
            0.9983444485405005,
            0.995025109434165,
            1.0133327035835014,
            0.9917766990937722,
            1.0066331874211134,
            1.0115315868103725,
            0.9902284640490554,
            1.0065786407249822,
            0.9836608873395029,
            0.978406332169925,
            1.0033954223657984,
            1.0084598312143798,
            1.0067110902804663,
            0.9866672964164985,
            1.0,
            1.0033782166572067,
            1.0084171068325098,
            0.998330629899973,
            1.0033443231130639,
            1.0,
            1.0099995276876261,
            1.006600351395226,
            1.0098356086197628,
            1.004869905822199,
            1.0177730542838457,
            0.9904766494770832,
            1.0032049722484437,
            0.9920099334215011,
            1.0,
            0.9983897675267611,
            0.996774340992641,
            1.0145657143980138,
            0.9968103618044055,
            1.0047997668129482,
            1.0079613984986158,
            1.001579703054199,
            1.0047316345849922,
            1.0047093516538341,
            0.9921878706412588,
            0.9984252721505874,
            1.0,
            1.0,
            0.9936911538866771,
            0.992063874564236,
            1.007999611354914,
            0.9936510996513888,
            0.9872178335957886,
            0.9935278048908309,
            1.008142946625787,
            0.9919228253760629,
            1.0,
            1.008142946625787,
            1.0048463047743623,
            0.9967847124140041,
            1.003225659007359,
            0.9887464934490142,
            1.0016259413344568,
            1.004869905822199,
            0.9967691301504251,
            0.9983793288978124,
            1.003246603881466,
            1.0161837631753061,
            1.0,
            0.9968154406005538,
            1.0287525989542745,
            0.9875782254338349,
            0.9968554960391345,
            1.0031544230566614,
            1.0,
            1.0125780158434623,
            1.0124217745661652,
            1.004601012719534,
            0.9862601789328924,
            0.9953562554247521,
            0.9828934983331078,
            0.9968355950714713,
            0.9872989862965283,
            0.9967847124140041,
            1.00645458285177,
            0.9967917838525194,
            1.0096491170976496,
            0.9968154406005538,
            1.0031947332171416,
            1.0047768390991694,
            0.9920764510199563,
            1.0031947332171416,
            1.0031845593994464,
            0.9952383247385416,
            0.9984051809022028,
            1.0031947332171416,
            0.9872585391639566,
            1.003225659007359,
            0.9967847124140041,
            1.011293071362808,
            0.9968103618044055,
            1.0,
            0.9984000777290172,
            0.9935868116040757,
            0.9951615114889615,
            1.0016206711021876,
            0.9805834146724925,
            1.0,
            1.0132007027904522,
            0.9967428213496852,
            1.0032678225320995,
            0.9902284640490554,
            0.9934213592750178,
            1.0049666543784979,
            1.0115315868103725,
            1.0016285893251573,
            1.0113815893411975,
            0.993569424828008,
            0.9967639024454155,
            1.0097398116443983,
            1.0289408426136253,
            0.9906254447695105,
            1.006308846113323,
            1.0031346470507985,
            0.9859381671542659,
            1.004754129388026,
            1.0,
            1.0047316345849922,
            1.0,
            1.0047093516538341,
            1.0,
            1.0156242587174824,
            1.0061535586939259,
            1.0045869429974836,
            0.9863020029392463,
            1.006172550269112,
            1.0153367090651129,
            0.9909369715908917,
            0.9969513606250329,
            1.0061159239966448,
            1.0,
            1.0015196867107397,
            1.0166942600618625,
            1.0044773954972266,
            0.998514187404881,
            1.004464070557617,
            0.9925929480066933,
            0.9970150696685157,
            0.9880215025090301,
            0.9969698363772983,
            0.987842506314083,
            0.9969232206530371,
            1.010801962970946,
            1.0015266467852342,
            0.9908540818750983,
            1.0,
            0.9969232206530371,
            1.0,
            1.0046294127018338,
            1.0046080799977612,
            0.993884076003355,
            1.0,
            1.0030767793469628,
            0.9984663290934888,
            1.0015360266659203,
            1.004601012719534,
            1.0045799403557025,
            0.9984803132892603,
            1.0,
            1.0076099983670854,
            1.0196396192549604,
            0.9718502036189597,
            1.0121945574998688,
            1.0,
            1.0030149589520503,
            0.9984955321864932,
            1.001506734647287,
            1.0060057139383622,
            1.0029849303314844,
            1.004464070557617,
            0.9777758452136049,
            0.9954547545659476,
            1.001521999673417,
            0.9984803132892603,
            0.998478000326583,
            1.0,
            1.0030486393749671,
            0.9969606265785208,
            0.9786595243752295,
            1.0124604698530408,
            0.9969232206530371,
            1.003086275134556,
            0.9938464413060742,
            1.0046437445752476,
            0.9953777201119078,
            1.0046437445752476,
            0.9861331603357235,
            0.9843757412825176,
            1.0031744501743056,
            1.0031644049285287,
            1.0078860576416537,
            1.0172135795312356,
            1.0030767793469628,
            1.0076683545325564,
            0.998478000326583,
            1.0,
            0.9969513606250329,
            1.0061159239966448,
            0.9969606265785208,
            1.0015243196874837,
            1.0045659990202511,
            1.0030301636227017,
            0.9984894952651485,
            1.0,
            0.9984872101889762,
            1.0030301636227017,
            0.9984894952651485,
            1.0196693298708617,
            0.9970327835373545,
            0.9895808231722011,
            1.0060178068640133,
            1.0074734797368852,
            1.0089016493879366,
            1.0,
            0.9941179272242331,
            1.0044376572044558,
            1.0,
            0.9955819485931994,
            1.0014792190681518,
            1.0014770342109827,
            0.9926257210074401,
            1.002971625190238,
            1.0103698727906294,
            1.0043986180859779,
            0.9927010750400408,
            0.9941179272242331,
            0.9911246855910887,
            1.0044773954972266,
            1.0,
            0.998514187404881,
            0.9985119764807943,
            1.004470723098736,
            0.9985163917686772,
            1.0014858125951192,
            1.0029672164626455,
            0.9911246855910887,
            1.0014924651657422,
            0.9985097589670879,
            1.0089547909944532,
            1.011833752545215,
            0.9853808092047635,
            0.9910983506120634,
            0.9865245691013551,
            1.0015173807673523,
            0.9924245909432459,
            0.9893134725033608,
            1.006172550269112,
            0.9969326581869775,
            1.0046151690204443,
            0.9984686776380438,
            1.0030673418130227,
            0.9969420380016775,
            0.9953989872804663,
            0.9969184800746053,
            1.0015455225211414,
            1.0,
            1.0,
            0.998456862432722,
            1.0046365675634243,
            0.9630786478364449,
            0.9936073000300719,
            0.9887464934490142,
            1.0048778240033702,
            1.0080935192888447,
            1.0,
            1.0,
            0.998391692686588,
            0.9967847124140041,
            0.9774203869484873,
            1.0016500878488066,
            0.9752894568349162,
            1.0,
            1.008445541643017,
            0.988275263450558,
            1.0067793353781125,
            0.9966331572669961,
            1.0067564333144137,
            1.0067110902804663,
            1.006666351791751,
            0.9884111397835048,
            1.001674962364206,
            1.0100329693391916,
            0.9950333456215019,
            0.9850256645156076,
            0.9949326750141899,
            0.9779263179599972,
            0.9687514276577277,
            0.9892478189106343,
            1.0217380941030654,
            0.9929081323193206,
            1.0,
            1.0,
            0.9892862177529251,
            1.0036099368261058,
            1.0125893321974537,
            1.0053283477448,
            1.0053001069319818,
            0.9982426120334829,
            0.9823951818392402,
            1.0035840603631219,
            0.9946431088764627,
            0.9946142580431804,
            0.9891701895216835,
            0.9963505402160863,
            0.9743565028157057,
            0.994361159729092,
            0.9981097276324148,
            1.0037877045283772,
            1.0094335298970332,
            1.0,
            0.9719638897927371,
            1.0365406474316057,
            0.9944306535626165,
            0.9888064774392363,
            1.0113202358764397,
            1.0,
            1.0,
            0.9925376516261576,
            1.0225591659691042,
            1.011028878246989,
            1.0181809482799866,
            0.9964287392509751,
            0.9892478189106343,
            0.9909424607903896,
            1.0018280656179344,
            1.0036494597839136,
            1.0181809482799866,
            1.0035712607490248,
            1.0195720372711523,
            1.0104707233065442,
            1.0051846076933564,
            1.0068725175812632,
            0.9965871957664647,
            1.006848982704239,
            1.0085029915383183,
            0.9932549597891834,
            0.9983022888171008,
            0.9931976067693451,
            0.9862985685071575,
            0.9965279364064142,
            0.9930316783569431,
            1.0035086099441393,
            1.0052445139623682,
            0.9965218983112901,
            1.005235361653272,
            0.9982639682032071,
            1.0017390508443549,
            1.0,
            1.0,
            1.0,
            1.001736031796793,
            0.9948009303598304,
            0.9930316783569431,
            1.0105258298324178,
            1.0034720635935859,
            0.9982699749601639,
            1.0,
            1.00346604642678,
            0.996545925555606,
            0.9982669767866101,
            1.008683673218371,
            1.0034421728890561,
            1.0017151824898616,
            1.0,
            1.0017122456760597,
            0.9982906810978395,
            1.0017122456760597,
            1.0,
            0.9880313075251553,
            1.0051935771944878,
            0.9913910837967027,
            1.006947641421578,
            1.0,
            1.0034481073799268,
            0.9914058750286934,
            1.0086686242111063,
            0.9982818706046842,
            1.001721086444528,
            1.0,
            0.9793789692614825,
            1.0193009059174056,
            1.001721086444528,
            1.0,
            0.9948421338193252,
            1.0120927565821443,
            1.0119448148173735,
            1.0118038203689292,
            0.9966668241041247,
            0.976589738208553,
            1.0,
            1.008561228380299,
            1.0152794006460926,
            0.9899670306608084,
            0.986487133371173,
            0.9897230598592769,
            1.0034635521546518,
            1.0103443221397808,
            1.0068256084670706,
            1.0,
            1.011863836911697,
            1.0,
            1.001674962364206,
            0.9983278384434681,
            0.9949751129073819,
            1.003366842733004,
            0.9966444548597666,
            0.9983165786334981,
            0.9932549597891834,
            1.001697711182899,
            1.011863836911697,
            0.998325037635794,
            1.0033555451402332,
            1.0033443231130639,
            1.0033331758958752,
            0.9966778972569116,
            1.0,
            0.9983334120520624,
            0.996661259799946,
            1.005024887092618,
            0.995000236156187,
            1.001674962364206,
            1.0167216155653191,
            0.9720407769188257,
            1.0050758987286277,
            1.0,
            1.0,
            0.9797989436019766,
            1.0,
            1.0034362587906316,
            0.993151017295761,
            1.0051721610698905,
            1.005145547469585,
            1.0136512169341412,
            1.0,
            1.0,
            0.9932663145339923,
            0.9932206646218873,
            1.0170640211676765,
            1.0033555451402332,
            1.0,
            1.0016721615565318,
            1.001669370100027,
            0.9916670602603115,
            0.9983194077783524,
            1.0084171068325098,
            1.001669370100027,
            1.0116661156355637,
            0.9884684131896275,
            0.9983334120520624,
            1.0150243309002434,
            0.9934213592750178,
            1.0,
            1.0049666543784979,
            1.0082368477216948,
            1.0032678225320995,
            1.0032571786503148,
            1.0022739371315532,
            0.9977312219271499,
            1.0,
            1.0064932077629323,
            0.993548681985282,
            0.996753396118534,
            0.9934856426993702,
            1.0131141448263503,
            1.009711568066137,
            1.0064099444968875,
            1.0095536781983387,
            0.9936911538866771,
            1.006348900348611,
            1.0,
            1.006308846113323,
            0.9968653529492015,
            0.9874219841565377,
            0.9936308812011075,
            1.012819888993775,
            0.9952533926072069,
            1.0047692451269707,
            1.0,
            1.0015822024642642,
            0.9984202969458008,
            0.9984177975357357,
            1.0015847097960087,
            0.9873423802858854,
            1.0032049722484437,
            0.9984026333914292,
            1.0015999222709828,
            0.9984026333914292,
            1.007999611354914,
            0.9968255498256943,
            1.0,
            1.0063691187988926,
            0.9952533926072069,
            1.003179496751314,
            0.9984152902039913,
            1.0031744501743056,
            0.9984177975357357,
            0.9920764510199563,
            1.0063894664342834,
            0.9952383247385416,
            1.0,
            1.0015948190977972,
            0.9984077203002769,
            1.0,
            1.0031896381955945,
            0.9920512581217156,
            1.0,
            1.0,
            1.0,
            0.9967917838525194,
            1.0064338295116537,
            0.9952079001742876,
            0.998391692686588,
            1.00161089813266,
            0.998391692686588,
            0.9983923562070021,
            0.9983897675267611,
            1.003225659007359,
            1.0160796922696416,
            0.9920889876786784,
            1.0015948190977972,
            1.012738237597785,
            0.9968554960391345,
            1.0031544230566614,
            0.9905664881174034,
            1.0031744501743056,
            0.9936711901429426,
            1.0031845593994464,
            1.006348900348611,
            1.0141949037549767,
            1.0139962286365483,
            0.9953989872804663,
            0.9953777201119078,
            1.0077395742920796,
            0.989247813338557,
            0.9937891127169175,
            0.9984375741282517,
            1.0062594834659038,
            1.0,
            1.0,
            1.0093308190910322,
            1.0046222798880922,
            0.993865316373955,
            1.0046294127018338,
            1.0076801333296022,
            1.0045729590624508,
            1.0015173807673523,
            1.0,
            0.9878793455091932,
            0.9907979745609324,
            1.0092874891504955,
            1.0122693672520902,
            0.9863642636978424,
            1.0061441066636818,
            1.0091598807114048,
            1.006051159244095,
            1.001506734647287,
            0.9984955321864932,
            1.001506734647287,
            1.0030028569691811,
            1.0,
            1.0,
            1.0,
            1.0014969334076749,
            1.0,
            1.0,
            0.9955159121578689,
            1.0030028569691811,
            0.9970061331846501,
            1.0030028569691811,
            0.99400923613973,
            1.0,
            0.9984940447701589,
            0.9984917734484959,
            1.0060450766412978,
            1.0120114278767247,
            0.976259265043307,
            0.9908818797355622,
            1.0260755100076684,
            0.9895341026680626,
            1.0090660861110006,
            0.9850276356936801,
            1.0151999433962844,
            0.9970061331846501,
            0.9789769618868154,
            1.010735696345579,
            0.9924130961632384,
            0.9847101900083877,
            1.0,
            0.9891309472546055,
            0.9968604322307774,
            1.02362091774119,
            0.9892312722856298,
            0.9860037713634517,
            0.9779158458674824,
            1.0096802418591286,
            1.0031947332171416,
            0.9904430985634028,
            0.9919617810350101,
            0.9983793288978124,
            1.0194829093251139,
            0.9840739797645103,
            1.0080935192888447,
            0.9951815762711314,
            1.0,
            0.996774340992641,
            0.9983819512227077,
            1.003241342204375,
            0.9967691301504251,
            1.008103355510938,
            0.995177068621006,
            1.0016154349247877,
            0.9983871704963204,
            0.9919228253760629,
            1.0097715359509445,
            0.9903230229779232,
            1.0065143573006297,
            0.988673658558954,
            1.0098195118072673,
            1.0016206711021876,
            0.9902917073362464,
            1.0,
            0.9901965324037018,
            1.0,
            1.0132007027904522,
            1.0048857679754721,
            1.0178306628347964,
            0.9904430985634028,
            1.0,
            1.0080414733046517,
            0.9904278570344759,
            0.9983897675267611,
            0.9919358524816025,
            1.0048778240033702,
            1.0,
            1.0064721951091693,
            1.00161089813266,
            1.0016050582077283,
            1.0224348057391062,
            1.0,
            1.0031346470507985,
            0.993750296513007,
            1.0031445039608657,
            1.0031346470507985,
            1.0046872776152447,
            0.995334590454484,
            1.0015624258717482,
            0.9859601034518694,
            0.9968355950714713,
            1.0031744501743056,
            1.0110754172498504,
            1.0,
            0.996870258267048,
            0.990581296692332,
            1.0,
            1.0142623881640784,
            1.0,
            1.0,
            0.9984375741282517,
            0.9984351291335241,
            1.0062692941015967,
            1.0031151174632602,
            0.9984472781792294,
            1.0186616381820643,
            1.0137398210671076,
            1.0030149589520503,
            1.0,
            1.0105099993921343,
            1.0059432503804762,
            1.0044311026329482,
            0.9985294818060583,
            1.001472683802267,
            0.9941179272242331,
            1.0147921906815187,
            0.9620979640011803,
            1.0090935578353346,
            0.9984955321864932,
            1.0030104253861958,
            1.0044975327300205,
            0.9985075348342578,
            1.0,
            1.001494695947377,
            1.007462325828711,
            0.9940743584053547,
            0.9970195179341759,
            1.0,
            1.001494695947377,
            1.0029849303314844,
            0.9985119764807943,
            1.004470723098736,
            1.0029672164626455,
            0.9970415618636963,
            1.0029672164626455,
            1.0,
            1.0029584381363037,
            1.0,
            1.002949711597024,
            1.0029410363878835,
            1.0146620602865928,
            0.9971099618561767,
            0.9855079266360789,
            0.9823537816726995,
            1.0,
            1.0194601342997747,
            1.0,
            1.010278512617781,
            1.0058136798004047,
            1.0028900381438233,
            0.9913548703230549,
            0.9970931600997975,
            0.9941693714960166,
            1.0043986180859779,
            0.9941608600320326,
            1.0029367178907944,
            1.0029281188318346,
            1.010218494943943,
            0.9826597711370604,
            0.9955884454181748,
            1.0014770342109827,
            1.010323990589584,
            0.9970804300160163,
            0.9985359405840827,
            1.0043986180859779,
            1.0,
            0.9927010750400408,
            0.9970589636121165,
            1.0088491347910722,
            0.9985380809204764,
            0.9985359405840827,
            1.0087972361719557,
            0.9970931600997975,
            1.0043729713779876,
            1.0,
            0.9956460682409762,
            0.9912540572440249,
            1.0,
            1.0117641455515336,
            0.9912794802993927,
            0.9985337939713408,
            1.0029367178907944,
            1.0029281188318346,
            1.0072989249599593,
            0.986957133972471,
            1.0044050768361916,
            0.9970761618409527,
            1.0219930904298893,
            0.994261385747559,
            1.0072146700237763,
            1.0057303931235282,
            0.9886045143654629,
            1.0086451296769452,
            0.9985714946676846,
            0.9971389022419656,
            1.0100425749417716,
            1.0042611675944242,
            1.0070746748970723,
            1.0,
            0.984548465507683,
            1.017120498974907,
            0.9929877157271056,
            0.9985847802406731,
            1.0014172254447797,
            1.00141236072951,
            0.9971764074424075,
            0.9985856376369269,
            1.0014163656172945,
            1.0014172254447797,
            0.9773993692980224,
            1.0014450190719115,
            0.9985570659952447,
            0.9971099618561767,
            1.001449207336392,
            1.0014471101737117,
            0.9927749046404419,
            1.0072776772718433,
            0.9985549809280884,
            0.9927644491314409,
            0.9956270286220125,
            0.9926797029204132,
            1.0073742789925602,
            1.0014640594159174,
            1.008771514477142,
            0.9971015853272158,
            1.0,
            1.0,
            1.0058136798004047,
            1.0028900381438233,
            1.0144085494615753,
            1.0028407783962827,
            0.9872527094443488,
            1.0071732678155512,
            1.0014244357043172,
            1.0,
            1.0099597463849488,
            0.9971832351171894,
            1.00282472145902,
            0.9943636192577768,
            1.0042519639887608,
            0.9901245635422525,
            0.9957266928870486,
            1.0128778357590886,
            1.0084741643770603,
            1.0168059126875002,
            1.0027546903622084,
            0.9848908241090618,
            0.9958161003910055,
            1.0056019708958333,
            1.0027853818200272,
            0.9972223549819087,
            0.9888584727198915,
            1.0,
            1.0197173541796742,
            0.986188501787946,
            1.0014004927239584,
            0.9958043977385648,
            1.0,
            1.0056177060086142,
            0.9986034190788786,
            1.0013985340871452,
            1.0055863236844858,
            1.0138882250904562,
            1.0,
            0.9958906046800522,
            1.001375450710696,
            1.015109175890938,
            0.9797032455633377,
            1.0124303483908486,
            1.0068209621698945,
            1.0,
            1.0054198014750855,
            1.0,
            0.9959570608214097,
            1.0013531169624441,
            1.0054051540441549,
            1.002688047710126,
            0.9986595792598382,
            1.0026844397590524,
            1.0,
            1.002677252835097,
            1.0,
            1.0,
            1.0080130154071505,
            0.998675560607848,
            1.0079571751491299,
            1.0013157263928323,
            1.011825977784398,
            0.9948054406174585,
            1.020886734914989,
            1.0102297023757139,
            1.0088629131029676,
            1.0075278675867394,
            0.9962641888979357,
            0.9937503004663238,
            1.013835808620773,
            0.9913155508899174,
            1.006257521058965,
            0.9950251136091843,
            1.0024998798134706,
            1.0,
            0.991272239006378,
            1.0037734023511198,
            0.9974938551255482,
            0.9937163535015244,
            0.996207507190894,
            0.9949240925787972,
            1.0076527055567317,
            0.993671177980824,
            0.9885355639904282,
            1.0064429964367883,
            1.0089624499782288,
            1.0025379537106014,
            1.0063313842952972,
            1.0037734023511198,
            1.0050122897489036,
            0.9800483080813817,
            1.0050888226174473,
            0.9962027067884944,
            1.0254143561147748,
            0.9962827050469428,
            1.0049748863908154,
            1.014850778237898,
            1.0097556399793632,
            1.007246040224813,
            1.0191861946773784,
            1.0117641455515336,
            1.0232570729181378,
            0.9852256860981595,
            0.9919265592386964,
            0.9860471684790285,
            0.9976416220293507,
            1.004727906130965,
            0.9905886835587729,
            1.0213766443572583,
            1.0209292472814573,
            1.0091135026466738,
            0.9932283158748473,
            0.9954524648008813,
            1.011417308278877,
            1.0067716841251528,
            0.9843034114040242,
            0.9840554049318506,
            1.0046294127018338,
            1.0023040399988805,
            1.033334108887855,
            0.984427877611584,
            1.0011298892296854,
            1.0270867365006113,
            1.0,
            1.0109884976677248,
            1.0,
            1.0,
            0.9739142488135338,
            0.993303889121079,
            1.0,
            1.0,
            1.0,
            1.0,
            0.9870792688335406,
            1.0092165898617511,
            0.9942922374429225,
            0.9873708381171067,
            1.0046511627906978,
            1.0,
            0.9953703703703702,
            0.9930232558139535,
            1.018735362997658,
            0.9839080459770116,
            1.0093457943925235,
            1.0023148148148147,
            1.0,
            1.0046189376443417,
            1.006896551724138,
            1.0011415525114156,
            1.0091220068415052,
            1.007909604519774,
            1.0291479820627802,
            1.0130718954248368,
            0.9634408602150538,
            1.0379464285714286,
            0.9870967741935482,
            0.9869281045751634,
            1.0044150110375274,
            1.0197802197802197,
            1.0021551724137931,
            1.0,
            0.9967741935483869,
            1.0032362459546926,
            1.004301075268817,
            1.0053533190578159,
            1.0010649627263046,
            0.9904255319148937,
            1.0053705692803436,
            1.012820512820513,
            1.0,
            0.9978902953586498,
            0.996828752642706,
            0.9978791092258749,
            0.9989373007438895,
            1.004255319148936,
            1.00635593220339,
            1.0021052631578946,
            0.9894957983193278,
            0.9957537154989385,
            0.9872068230277184,
            0.9892008639308856,
            1.009825327510917,
            1.0054054054054056,
            1.0053763440860215,
            0.9989304812834224,
            1.0042826552462527,
            0.9850746268656716,
            1.028138528138528,
            1.0210526315789472,
            1.0164948453608247,
            0.988843813387424,
            1.0133333333333334,
            0.9757085020242915,
            0.9854771784232365,
            0.9810526315789474,
            1.004291845493562,
            1.0,
            1.0149572649572651,
            1.0063157894736843,
            0.9937238493723849,
            0.9747368421052631,
            0.9762419006479481,
            1.0176991150442478,
            0.9891304347826088,
            1.0,
            1.0,
            1.0307692307692309,
            1.0063965884861406,
            1.0084745762711864,
            1.0231092436974791,
            1.02258726899384,
            0.9979919678714858,
            0.977867203219316,
            0.9979423868312756,
            1.0103092783505156,
            1.0030612244897958,
            1.0030518819938963,
            1.0,
            0.9929006085192698,
            1.0112359550561798,
            0.9919191919191919,
            0.9796334012219958,
            1.0187110187110189,
            0.9959183673469387,
            1.0040983606557379,
            1.004081632653061,
            0.9898373983739838,
            1.0184804928131417,
            0.9879032258064517,
            1.0081632653061225,
            1.0020242914979756,
            1.0161616161616163,
            0.9970178926441351,
            1.0069790628115654,
            1.000990099009901,
            1.0187932739861525,
            0.9766990291262135,
            0.9840954274353877,
            0.998989898989899,
            0.9949443882709808,
            1.0071138211382114,
            1.0040363269424823,
            0.984924623115578,
            0.9897959183673468,
            0.9896907216494846,
            1.0041666666666669,
            1.0072614107883817,
            1.0154479917610708,
            0.9939148073022314,
            1.010204081632653,
            1.0080808080808081,
            0.9979959919839679,
            0.9909638554216866,
            0.9969604863221885,
            1.0020325203252032,
            0.9939148073022314,
            0.9653061224489796,
            1.014799154334038,
            1.0104166666666667,
            0.9969072164948454,
            0.9968976215098243,
            1.0062240663900415,
            0.9876288659793815,
            1.0062630480167014,
            0.9896265560165973,
            1.0419287211740043,
            0.9859154929577466,
            1.0,
            1.0,
            0.9979591836734693,
            1.0,
            0.9918200408997955,
            1.0216494845360826,
            1.0090817356205852,
            1.002,
            0.9800399201596807,
            1.0030549898167005,
            1.0,
<<<<<<< HEAD
        ]
    },
    CTM: {
        name: 'Cybertruck Motors',
        priceHistory: [
            1.0,
            1.0,
            0.9912970241437395,
            0.9912294675729254,
            1.0088481352845065,
            1.0,
            0.9912294675729254,
            1.0,
            1.0,
            1.0,
            0.9911518647154938,
            1.008927123682551,
            1.0,
            1.0,
            1.0,
            1.0,
            1.0088481352845065,
            0.9912294675729254,
            0.9911518647154938,
            1.0,
            1.0178542473651022,
            1.0087793826111582,
            1.0173884053902302,
            1.0256368245865168,
            0.991667997881267,
            1.0,
            1.0,
            1.0,
            1.0,
            1.058822531963238,
            1.0,
            1.0476194289191743,
            1.0,
            1.0227312681051417,
            1.0074061895117594,
            1.0367661241264707,
            1.0,
            0.9858107818023097,
            0.9928069042106104,
            1.0072452112882,
            1.0,
            0.9928069042106104,
            1.0,
            0.9927547887118001,
            1.0072980874739486,
            1.0,
            0.9782643661354,
            1.0,
            1.0,
            0.9851876209764809,
            1.0075175421960934,
            0.9925385495723407,
            0.9774397875971933,
            1.0076910540081179,
            1.038169467506662,
            0.9926482588762445,
            1.0,
            1.0,
            1.0,
            1.0074061895117594,
            1.0,
            1.0,
            0.9852965177524889,
            0.9925385495723407,
            1.0075175421960934,
            1.0,
            1.0,
            0.9850695699313335,
            1.0,
            0.9848509168176225,
            0.992308945991882,
            0.9922493352103865,
            1.0,
            0.9921887931646028,
            1.0157454042803349,
            1.0,
            0.9922493352103865,
            1.007811206835397,
            1.0,
            0.9922493352103865,
            1.007811206835397,
            1.0,
            1.0077506647896137,
            0.992308945991882,
            1.0310104802127327,
            0.9774397875971933,
            1.0076910540081179,
            0.9923676468323039,
            1.0,
            1.0153821080162357,
            1.0,
            0.9924254584088111,
            1.0,
            1.0076323531676963,
            0.9924254584088111,
            0.9923676468323039,
            1.0076910540081179,
            1.0,
            1.0152724080035735,
            1.0,
            1.0075175421960934,
            1.0,
            0.9776081195036742,
            0.9923676468323039,
            1.0,
            0.992308945991882,
            1.0,
            1.0,
            0.9922493352103865,
            1.0156224136707943,
            0.9846178919837643,
            1.007811206835397,
            1.0,
            0.9922493352103865,
            1.0,
            0.9921887931646028,
            0.9763739493795579,
            1.0,
            1.0,
            1.0,
            1.0080632038013408,
            1.0,
            0.9920012914161184,
            0.9919367961986593,
            1.0081287475494818,
            0.9919367961986593,
            1.0081287475494818,
            1.0,
            0.9919367961986593,
            0.9756055547808682,
            0.9666719915250676,
            1.0258577951728638,
            0.9915979923356054,
            1.0169463991039442,
            0.9750039936438005,
            1.0,
            1.0,
            1.0,
            1.0256368245865168,
            1.0083404097898925,
            0.9917285772652608,
            1.0,
            1.0083404097898925,
            1.0082630846069822,
            1.0,
            1.0163907312151634,
            0.9919367961986593,
            1.0,
            1.0,
            1.0,
            0.9918712524505179,
            0.9754056333835035,
            1.0,
            0.9831959846712109,
            0.9658089440008278,
            1.0088481352845065,
            1.0438615123194563,
            0.9915979923356054,
            1.0084731995519722,
            0.9915979923356054,
            1.0,
            0.9830536008960559,
            0.9568949771689498,
            1.0180150701242512,
            0.9911518647154938,
            0.9821457526348976,
            1.00908940822541,
            0.9819849298757488,
            1.0275183501948406,
            1.008927123682551,
            1.0177051990607227,
            1.0086942026951151,
            1.0,
            0.965514242226571,
            0.9732186289523466,
            0.9816451771152476,
            1.0280423180643823,
            1.01817881645082,
            1.008927123682551,
            1.0088481352845065,
            1.0,
            1.0087793826111582,
            0.9826028214486243,
            1.0177051990607227,
            0.9912970241437395,
            1.0087793826111582,
            1.0,
            1.0173884053902302,
            1.0,
            0.9743545521963335,
            1.0,
            0.9561473378646276,
            1.0275183501948406,
            1.008927123682551,
            0.9911518647154938,
            1.0,
            1.008927123682551,
            1.0088481352845065,
            0.9912294675729254,
            0.9911518647154938,
            1.008927123682551,
            0.9911518647154938,
            1.008927123682551,
            1.0,
            1.0088481352845065,
            1.0,
            1.0175499150382328,
            1.0258577951728638,
            0.9915979923356054,
            1.0084731995519722,
            0.9915979923356054,
            1.0169463991039442,
            1.0250044140273586,
            1.016257495098964,
            1.0,
            1.0079987085838815,
            1.0,
            1.0,
            0.9841295261278284,
            0.9919367961986593,
            1.016257495098964,
            1.0,
            1.0,
            1.0079987085838815,
            1.0,
            1.0,
            1.031748955047003,
            1.0,
            1.0153821080162357,
            0.9848509168176225,
            1.0076910540081179,
            0.9847352936646078,
            0.9922493352103865,
            1.0156224136707943,
            1.0,
            1.0153821080162357,
            1.0,
            1.0075821849227642,
            0.9924748719893799,
            0.9848509168176225,
            1.0076910540081179,
            1.0076323531676963,
            1.0,
            1.015156726513953,
            0.9925385495723407,
            1.0,
            0.9924748719893799,
            1.0,
            1.0,
            1.0075821849227642,
            1.0075175421960934,
            0.9850695699313335,
            1.0,
            1.0,
            1.0075821849227642,
            1.0,
            0.9924748719893799,
            1.0,
            1.0,
            1.0,
            1.0,
            1.0,
            0.9924254584088111,
            1.0076323531676963,
            1.0,
            1.0,
            1.0,
            0.9848509168176225,
            0.9846178919837643,
            1.0156224136707943,
            1.0,
            1.0,
            0.9846178919837643,
            1.0156224136707943,
            0.9846178919837643,
            0.9843697041830549,
            1.0,
            0.9920647630639142,
            1.0079987085838815,
            0.9920647630639142,
            1.0,
            0.9920012914161184,
            1.0,
            0.9919367961986593,
            0.983742504901036,
            0.9669393234443137,
            1.0,
            1.0085456081955058,
            1.0,
            0.9745804013440837,
            0.9652144160583942,
            1.0090075350621257,
            0.9910728763174488,
            1.0,
            1.0180150701242512,
            1.0088481352845065,
            1.0087793826111582,
            1.0,
            1.0,
            0.9826028214486243,
            1.0088481352845065,
            0.9912294675729254,
            1.0088481352845065,
            1.0175499150382328,
            1.0258577951728638,
            1.0,
            0.9831959846712109,
            1.0,
            1.0170912163910113,
            1.0084020076643945,
            1.0166724119086255,
            1.0,
            1.0163907312151634,
            0.9838735923973183,
            1.0081953656075817,
            0.9756055547808682,
            1.0166724119086255,
            0.9836009989910851,
            1.0166724119086255,
            0.9918046343924184,
            1.0,
            1.0,
            0.9917285772652608,
            1.0166724119086255,
            0.9918046343924184,
            1.0082630846069822,
            0.9918046343924184,
            0.9834654926582785,
            0.9747939770068166,
            1.0,
            0.9913807349423787,
            1.0086942026951151,
            1.0,
            1.0086192650576211,
            1.0,
            1.0170912163910113,
            1.0168124936412657,
            1.0,
            0.9917285772652608,
            1.0083404097898925,
            1.0082630846069822,
            1.0,
            0.9836009989910851,
            1.0,
            1.0083404097898925,
            1.0,
            0.9917285772652608,
            0.991667997881267,
            1.0,
            1.0168124936412657,
            0.9917285772652608,
            1.0,
            1.0,
            0.991667997881267,
            1.0,
            0.9831959846712109,
            1.0,
            1.0,
            1.0085456081955058,
            1.0,
            1.0084731995519722,
            1.0084020076643945,
            1.0166724119086255,
            1.0245860968227454,
            0.9920012914161184,
            1.0,
            1.040324155438391,
            0.9844986704207728,
            1.0,
            0.9842466515197255,
            1.0,
            1.0240041971023852,
            0.9921887931646028,
            1.0,
            1.0157454042803349,
            1.0155013295792272,
            0.9771029404969118,
            1.0,
            1.007811206835397,
            1.0,
            1.0,
            0.9922493352103865,
            1.0,
            0.9843697041830549,
            0.9523805710808256,
            0.9833359957625338,
            0.9830536008960559,
            1.0086192650576211,
            1.0085456081955058,
            1.0169463991039442,
            1.0250044140273586,
            1.0,
            1.0,
            1.016257495098964,
            0.9920012914161184,
            1.0080632038013408,
            1.0160054885185035,
            1.0,
            1.0157454042803349,
            1.0,
            1.0,
            1.0155013295792272,
            1.0152724080035735,
            0.9924748719893799,
            1.0227312681051417,
            0.9851876209764809,
            1.0,
            1.0,
            1.0,
            1.0150350843921867,
            1.0,
            1.0148123790235188,
            0.9854038250521029,
            1.0148123790235188,
            1.0219016267886205,
            0.9857094470427996,
            1.0144977335867817,
            1.0,
            1.0071416732126,
            1.0141820627674343,
            1.0069918722131288,
            1.0277803078604608,
            0.9932443487034057,
            1.0204048016142648,
            1.0266690880718887,
            1.0064924429536357,
            0.9935494369589273,
            1.0064924429536357,
            0.9870988739178547,
            0.9934651297742139,
            1.006577855658881,
            0.9869236653302385,
            0.9867570908362009,
            1.0201377274296972,
            1.0131557113177616,
            1.0129848859072714,
            1.0,
            1.0,
            0.9935907800363469,
            1.0,
            1.0,
            1.0193581982685673,
            1.0063280631401497,
            1.0,
            0.9937117294330404,
            1.0126561262802993,
            0.9812467683149836,
            0.9808947896049147,
            1.0064924429536357,
            0.9806483108767818,
            1.0131557113177616,
            1.0,
            0.9805161197334888,
            0.9933785454181003,
            0.9933344095134322,
            1.0067103187232112,
            0.9733376380537283,
            1.0273927164674175,
            1.020003497585321,
            0.9934651297742139,
            1.0131557113177616,
            0.9805161197334888,
            1.013249590752681,
            0.9803887951044523,
            1.013337907098753,
            1.0,
            1.0,
            1.006577855658881,
            1.0,
            0.9869236653302385,
            0.9933785454181003,
            0.9933344095134322,
            1.0201377274296972,
            0.9868376510882335,
            1.0,
            0.9933344095134322,
            0.9798690438303663,
            0.9931449105106764,
            1.0344841982215172,
            1.0,
            0.9466685499818396,
            1.063377029379374,
            0.9933785454181003,
            0.9866688190268642,
            1.0202669538897826,
            1.0066281361707814,
            1.032889278294404,
            0.9808947896049147,
            0.9935075570463644,
            0.9934651297742139,
            0.973681939770472,
            1.0,
            1.0067556512965943,
            1.0134206374464223,
            1.0066281361707814,
            1.0,
            1.006577855658881,
            1.0,
            1.0,
            1.0,
            1.006534870225786,
            0.9935075570463644,
            1.0,
            0.9869236653302385,
            0.9867570908362009,
            1.0067103187232112,
            1.006665590486568,
            1.0,
            0.9867570908362009,
            1.0,
            0.9865793625535775,
            1.0204048016142648,
            1.0,
            1.0,
            1.0,
            1.0,
            1.0,
            1.006665590486568,
            1.0,
            0.9933785454181003,
            1.013337907098753,
            0.9934155067471143,
            1.0,
            1.0,
            1.0,
            1.0066281361707814,
            1.0,
            0.9934155067471143,
            1.0,
            1.0066281361707814,
            1.006577855658881,
            1.0,
            1.0,
            1.006534870225786,
            1.0129848859072714,
            1.0128249073541111,
            0.987337488186764,
            0.9935907800363469,
            1.0064505630410727,
            1.0,
            1.0128249073541111,
            0.987337488186764,
            1.0,
            1.0,
            1.0,
            1.0,
            0.9935907800363469,
            1.0,
            1.0064505630410727,
            1.006409219963653,
            1.0063748297046502,
            1.0063280631401497,
            1.0,
            0.9937117294330404,
            1.0063280631401497,
            1.0,
            1.0,
            0.9937117294330404,
            1.0063280631401497,
            1.0062882705669596,
            1.0,
            1.0,
            0.9937510246806149,
            1.018864811700879,
            1.0061718399681132,
            0.9938660179872368,
            1.0123499078272133,
            1.0121930963205394,
            1.0,
            1.0,
            0.9939768921736066,
            0.9757554634283547,
            1.0,
            1.006210168131999,
            1.0061718399681132,
            1.012274153714742,
            1.0,
            0.9939403945163933,
            1.0121930963205394,
            0.9939768921736066,
            1.0060596054836066,
            1.0,
            1.012046215652787,
            1.0,
            1.011908837041708,
            0.9941126897648637,
            0.9822513820400464,
            1.0,
            1.0060231078263935,
            1.0059870471955727,
            1.0,
            1.0,
            1.0059514157883676,
            1.0,
            1.0,
            0.9940837940133487,
            0.9880971684232652,
            1.0180693234791804,
            0.9881675880266976,
            1.0119740943911457,
            0.9940837940133487,
            1.0059514157883676,
            1.0059221759220565,
            1.029406877232964,
            1.0342859448957356,
            0.9834280936454849,
            0.9943829459209758,
            1.0112975672039948,
            0.9720659685037595,
            1.0172443784717793,
            0.9943455163136414,
            0.9886381876132169,
            1.0,
            1.0057461933643352,
            0.9885732734515978,
            1.0115588058622158,
            1.011432491798931,
            0.9886967327116442,
            0.994286636725799,
            0.9885076132713294,
            1.0,
            1.0,
            0.9941870014077897,
            0.9883001256718725,
            1.0059221759220565,
            0.9941126897648637,
            1.0059221759220565,
            1.0117627508931857,
            1.0348838573439698,
            0.9887602237752725,
            0.9886381876132169,
            1.0114923867286707,
            1.0,
            1.0,
            1.0,
            1.0,
            1.0170484510788562,
            1.0,
            0.9888286419641749,
            1.0112975672039948,
            0.9944143209820875,
            1.0056170540790241,
            1.0,
            0.9888286419641749,
            1.0112975672039948,
            0.9944143209820875,
            1.0112341081580483,
            0.9833360424642255,
            1.0056487836019974,
            1.0056170540790241,
            1.0055856790179125,
            1.0,
            0.9944453474880752,
            0.9888286419641749,
            1.016946350805992,
            1.0,
            0.9888906949761503,
            1.0224682163160967,
            0.9890127556252807,
            1.005554652511925,
            1.0055239687848383,
            1.0,
            0.9945063778126404,
            1.0110535117056856,
            1.0109271541434424,
            1.0108090420745508,
            1.0053467280290482,
            1.0053236591570156,
            0.9947045321091122,
            0.9893634148697529,
            1.0215073010913667,
            0.9789455238661647,
            1.0161318318904726,
            0.9947045321091122,
            0.9893634148697529,
            1.0107509384017879,
            1.02659682941751,
            1.0,
            0.9689117274979874,
            1.0106988513436959,
            1.0211605188704427,
            0.9792779700356519,
            1.0052901297176107,
            1.010524583025791,
            0.9947925150943495,
            1.015704234279919,
            0.9793847674025535,
            0.9842078153790602,
            1.0,
            0.9946532719709519,
            0.9784926989086332,
            1.0054991657011714,
            1.005463577071721,
            0.9673911851470056,
            1.0112341081580483,
            1.0,
            0.9888906949761503,
            1.0,
            1.0112341081580483,
            1.0,
            1.0111093050238498,
            1.0219800322632504,
            0.989249061598212,
            0.9891267395571737,
            0.9945063778126404,
            0.9944760312151616,
            0.9944453474880752,
            0.9944143209820875,
            0.9943829459209758,
            1.0112975672039948,
            1.0,
            1.011171358035825,
            1.0055239687848383,
            1.0054991657011714,
            1.005463577071721,
            1.0108677772050843,
            1.0107509384017879,
            1.0,
            0.9840451223046291,
            1.0,
            1.0054045210372755,
            1.0107509384017879,
            1.0,
            1.0053236591570156,
            1.0052901297176107,
            1.0,
            0.9947377084871044,
            1.0,
            1.0,
            1.0052901297176107,
            0.9947377084871044,
            1.0,
            1.031746116478941,
            1.0102547121488847,
            1.0101506204579558,
            0.994975689638564,
            1.0,
            0.9936305732484076,
            1.0153846153846153,
            0.9949494949494949,
            0.9898477157360406,
            1.0102564102564102,
            0.9949238578680203,
            1.0051020408163265,
            1.0,
            0.9898477157360406,
            1.0256410256410258,
            1.01,
            0.9950495049504949,
            1.0199004975124377,
            0.9853658536585367,
            0.9950495049504949,
            0.9950248756218907,
            1.035,
            1.0,
            0.9903381642512077,
            1.0146341463414634,
            1.028846153846154,
            0.9906542056074766,
            0.9905660377358491,
            0.9904761904761905,
            0.9855769230769229,
            1.0146341463414634,
            1.0,
            0.9903846153846154,
            1.0097087378640777,
            1.0,
            1.0,
            1.0192307692307692,
            1.0,
            0.9952830188679245,
            1.0047393364928912,
            0.9952830188679245,
            0.9952606635071091,
            0.9952380952380951,
            1.0,
            0.9952153110047848,
            0.9951923076923076,
            1.0048309178743962,
            0.9951923076923076,
            1.0096618357487923,
            0.9904306220095693,
            1.0048309178743962,
            1.0,
            0.9951923076923076,
            1.0,
            1.0048309178743962,
            0.9951923076923076,
            0.9855072463768116,
            1.0,
            0.9705882352941176,
            1.0,
            1.0,
            0.98989898989899,
            0.9795918367346939,
            1.015625,
            1.0,
            0.9948717948717949,
            1.0051546391752577,
            0.9948717948717949,
            0.979381443298969,
            0.9842105263157895,
            0.9839572192513368,
            0.9836956521739131,
            1.0055248618784531,
            1.0,
            1.0,
            1.0,
            0.9945054945054945,
            1.0,
            1.0055248618784531,
            1.0164835164835164,
            1.010810810810811,
            0.9946524064171123,
            0.9731182795698925,
            0.9779005524861878,
            1.0169491525423728,
            1.0,
            1.011111111111111,
            1.0,
            1.0054945054945055,
            0.994535519125683,
            0.978021978021978,
            0.9943820224719101,
            0.9943502824858756,
            1.0,
            0.9943181818181818,
            1.0057142857142858,
            1.0,
            0.9715909090909091,
            1.023391812865497,
            0.9942857142857143,
            0.9942528735632183,
            0.9826589595375722,
            1.011764705882353,
            1.0,
            1.005813953488372,
            1.0115606936416186,
            1.0171428571428571,
            1.0,
            0.9887640449438202,
            1.0113636363636365,
            1.0056179775280898,
            1.0,
            0.994413407821229,
            1.0,
            1.0056179775280898,
            1.0,
            1.0,
            1.005586592178771,
            0.9888888888888889,
            0.9943820224719101,
            0.9943502824858756,
            1.0113636363636365,
            1.0,
            0.9831460674157303,
            0.9942857142857143,
            1.0,
            1.0,
            1.0,
            0.9942528735632183,
            1.0,
            1.0289017341040463,
            1.0112359550561798,
            1.0166666666666666,
            0.9890710382513661,
            1.0,
            1.0055248618784531,
            1.0,
            0.989010989010989,
            1.0,
            0.9944444444444445,
            1.0,
            0.9888268156424581,
            1.0,
            1.0,
            1.0,
            0.9943502824858756,
            1.0,
            1.0056818181818181,
            1.0,
            0.983050847457627,
            1.0057471264367817,
            1.0057142857142858,
            1.0056818181818181,
            0.983050847457627,
            1.0172413793103448,
            0.9887005649717514,
            0.9942857142857143,
            1.0,
            0.9942528735632183,
            0.9826589595375722,
            1.0176470588235293,
            1.0,
            0.9884393063583815,
            1.0058479532163742,
            1.0,
            1.005813953488372,
            0.9884393063583815,
            1.0116959064327486,
            0.9942196531791907,
            1.0,
            1.0,
            0.9941860465116279,
            1.0058479532163742,
            1.0174418604651163,
            0.9942857142857143,
            0.9885057471264368,
            1.0116279069767442,
            0.9885057471264368,
            1.005813953488372,
            0.9884393063583815,
            1.0,
            1.0,
            1.0058479532163742,
            1.005813953488372,
            1.0,
            1.0,
            1.0115606936416186,
            1.0057142857142858,
            1.0170454545454546,
            0.9888268156424581,
            0.983050847457627,
            0.9827586206896551,
            1.0058479532163742,
            1.0,
            1.0116279069767442,
            1.0172413793103448,
            0.9887005649717514,
            1.0114285714285713,
            0.983050847457627,
            1.0,
            1.0057471264367817,
            0.9942857142857143,
            1.0,
            1.0,
            1.0,
            1.0057471264367817,
            0.9542857142857143,
            1.0059880239520957,
            1.0,
            1.0,
            0.9880952380952381,
            1.0060240963855422,
            0.9940119760479041,
            1.0,
            1.0060240963855422,
            1.0179640718562875,
            1.011764705882353,
            1.005813953488372,
            0.9884393063583815,
            1.0116959064327486,
            1.0,
            1.0,
            1.0,
            0.9884393063583815,
            1.0116959064327486,
            1.0057803468208093,
            1.0057471264367817,
            0.9828571428571429,
            0.9883720930232558,
            0.9941176470588236,
            1.017751479289941,
            0.9651162790697674,
            0.9819277108433735,
            1.0122699386503067,
            0.9939393939393939,
            1.0121951219512195,
            0.9819277108433735,
            0.98159509202454,
            1.0,
            0.9875,
            0.9873417721518988,
            0.9743589743589743,
            1.013157894736842,
            1.0,
            1.0,
        ]
    }

=======
        ],
    },
>>>>>>> dba543a0e28687e9fa0af9dfa74742ad21940a3c
}

for (const symbol in instruments) {
    instruments[symbol].priceHistory = instruments[symbol].priceHistory.map(p =>
        p < 1 ? (p - 1) * 5 + 1 : (p - 1) * 50 + 1,
    )
}
