import {Decision} from './decisions'

export interface Narrative {
    body: string
    image?: string
    decision?: Decision
}

export const narratives: Narrative[] = [
    {
        body:
            'Welcome to invest quest. You are an adventurer with a meagre $5 to your name but you wish you had the same investment opportunities as those with $500,000. As you move through the game you’ll discover new ways of investing.',
        decision: {type: 'nothing', body: 'Begin my investing jouney'},
    },
    {body: 'Sitting on your couch at home next to Alan who jumps up with a WHOOP!'},
    {
        body:
            'You ride Hyojin’s food train to a local restaurant. When, there she pulls out her phone and show you the returns they’ve been getting on a new investment they’ve made. They swear it’s about to go big!',
    },
    {
        body:
            'Standing at the watercooler at work and one of your Don tells you he’s about to make one $5 order instead of his normal order of 200 5 cent ones. That must be a sign...',
    },
    {
        body:
            'You see a rainbow with a pot of gold and a company logo on it. You awaken realising it was a dream but recognise the logo as one you could invest in…',
    },
    {
        body:
            'On your morning commute before you start up your podcast you overhear two well dressed people discussing an investment. You get the impression they must work in finance…',
    },
    {body: 'Tom brings Ayla into the office. That’s surely good luck. Time to invest!'},
    {body: 'Champ turns up with not 1 but 3 cakes. It’s gonna be a good day.'},
    {body: 'Nat exclaims CRAPOLA! Maybe you should be a bit more conservative today...'},
    {
        body:
            'You’re at the gym with your workmate Lance lifting some heavy tin. He’s been doing pretty well in his trades of late. You decide to pick his brains for investment tips…',
    },
    {
        body:
            'Hanging out at the pub with your mate Leighton who has a hot investment tip for you. His tips usually pay off…',
    },
    {
        body:
            'Renae makes an executive decision to go with Cannasouth plants in the office so we can be an even better ‘b’ corp.',
    },
    {body: 'Don’s got his black soled shoes on for hacky today. It’s gonna be a good one.'},
    {body: 'Jen starts a 5th D&D group at work. You finally get a chance to play!'},
    {body: 'It’s your birthday, you’re given a gift. A gift of knowledge of what to invest in.'},
    {body: 'You’re at home doing your budget when you realise that a dollar earned is a dollar saved as well.'},
    {
        body:
            'A brass band is busking on the street, they’re playing some sweet tunes when they play the Mr Whippy tune you get a spark of inspiration of what you need to invest in.',
    },
    {
        body:
            'You miss out on a christmas sale - your money is burning a hole in your pocket. Fortunately you decide to invest it.',
    },
    {body: 'You catch up with a childhood best friend who’s now working at Goldman Sachs, they give you a sneaky tip.'},
    {
        body:
            'You’re doing house renovations as you paint the walls your mind begins to wonder. You realise there’s a good bet.',
    },
    {
        body:
            'You’re on the golf course, currently shooting 6 under. You think… hmmm if only investing was as easy as beating Leighton at golf.',
    },
    {
        body:
            'Borrowing your mates car you accidently ding it! You’re gonna have to recoup the cost back somehow when it hits you… Invest!',
    },
    {
        body:
            'Walking to work while it’s raining and your Allbirds are filling up with water. Squelch! You realise you’re gonna need to invest so you can get some waterproof chucks.',
    },
    {body: 'You are considering proposing and you’re gonna need to pay for that somehow… '},
    {
        body:
            'Your cat incurs unexpected vet bills… maybe you should self insure against vet bills using one of those kitschy new fangled investing apps.',
    },
    {body: 'Your small child wakes you up at 4am in the morning, if only you could afford a night nanny.'},
    {body: 'Hardy whispers in your ear, all care and no consideration and proceeds to give you investment advice…'},
    {body: 'New fastest typer joins the office… Martyn looks unimpressed…'},
    {
        body:
            'Champ discovers chewing gum is now a scarce and goes on the hunt for more. Let’s wait and see what happens...',
    },
    {body: 'Chris comes into the office with his pants at his waist. It’s a bad sign.'},
    {
        body:
            'Ding dong the school bell chimes. Paul says stay in school. Do you want to enter into higher education? Pay $200 to increase your earnings by $20 per turn.',
        decision: {type: 'school'},
    },
    // 31
    {
        body: 'You enter a brightly lit building with people huddled over contracts. A man in a cheap suit welcomes you in. I’m the loan shark, how much would you like to borrow?',
        decision: {type: 'loan-shark', rate: 0.05},
    },
    // 32
    {
        body: 'You visit the bankerman and he says he can do you a deal on a term deposit. Would you like to invest?',
        decision: {type: 'term-deposit', matureSteps: 10, rate: 0.01},
    },
]
