import React from 'react'
import {withStore, State, actions} from './store'

const totalWealth = (wealth: State['wealth']): number => {
    return wealth.cash + wealth.investments.reduce((sum, investment) => sum + investment.sellValue(investment), 0)
}

const wealthLevel = (totalWealth: number) => {
    if (totalWealth < 5) return 'Trump'
    if (totalWealth < 3000) return 'Newbie'
    if (totalWealth < 15000) return 'Newbie Trader'
    if (totalWealth < 100000) return 'Trader'
    if (totalWealth < 250000) return 'Big Time Trader'
    return 'Warren Buffet'
}

export const Wealth: React.FC = () => {
    const {state, dispatch} = withStore()

    const total = totalWealth(state.wealth)

    return (
        <div className="wealth">
            <p className="turn">Turn #{state.currentTurn}</p>
            <h2>
                Wealth:{' '}
                {total.toLocaleString('en-NZ', {
                    style: 'currency',
                    currency: 'nzd',
                    currencyDisplay: 'symbol',
                })}
            </h2>
            <div>
                Income:{' '}
                <strong>
                    {state.incomePerTurn.toLocaleString('en-NZ', {
                        style: 'currency',
                        currency: 'nzd',
                        currencyDisplay: 'symbol',
                    })}
                </strong>{' '}
                per turn
            </div>
            <div>
                Cash:{' '}
                {state.wealth.cash.toLocaleString('en-NZ', {
                    style: 'currency',
                    currency: 'nzd',
                    currencyDisplay: 'symbol',
                })}
            </div>
            <div>
                Investments:
                {state.wealth.investments.map((investment, i) => (
                    <div key={i}>
                        {investment.name}:{' '}
                        {investment.sellValue(investment).toLocaleString('en-NZ', {
                            style: 'currency',
                            currency: 'nzd',
                            currencyDisplay: 'symbol',
                        })}
                        <button onClick={() => dispatch(actions.sellInvestment(i))}>
                            {investment.sellValue(investment) < 0 ? 'pay back' : 'sell'}
                        </button>
                    </div>
                ))}
            </div>
            <p>
                Your level: <strong>{wealthLevel(total)}</strong>
            </p>
        </div>
    )
}
