import React from 'react'
import {withStore, actions} from './store'

export const Failure: React.FC = () => {
    const {dispatch} = withStore()

    return (
        <div className="failure">
            <h1>InvestQuest™</h1>
            <button
                onClick={() => {
                    dispatch(actions.play())
                }}
            >
                PLAY AGAIN!
            </button>
        </div>
    )
}

export const Victory: React.FC = () => {
    const {dispatch} = withStore()

    return (
        <div className="victory">
            <h1>InvestQuest™</h1>
            <h2>You WON!</h2>
            <button
                onClick={() => {
                    dispatch(actions.play())
                }}
            >
                PLAY AGAIN!
            </button>
        </div>
    )
}
