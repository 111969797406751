import produce from 'immer'
import {Instrument} from './instruments'

export interface InvestmentCommon {
    type: string
    name: string
    currentStep: number
    step(state: InvestmentCommon): InvestmentCommon
    sellValue(state: InvestmentCommon): number
    // Returns a reason or undefined if force sell not required
    shouldForceSell(state: InvestmentCommon): string | undefined
}

const step = <T extends InvestmentCommon>(state: T) =>
    produce(state, state => {
        state.currentStep++
    })

interface TermDeposit extends InvestmentCommon {
    type: 'termDeposit'
    initialInvestment: number
    maturesSteps: number // how long is it for
    currentStep: number
    rate: number
    step(state: TermDeposit): TermDeposit
    sellValue(state: TermDeposit): number
    // Returns a reason or undefined if force sell not required
    shouldForceSell(state: TermDeposit): string | undefined
}

export function startTermDeposit(initialInvestment: number, maturesSteps: number, rate: number): TermDeposit {
    const sellValue: TermDeposit['sellValue'] = state =>
        state.currentStep >= state.maturesSteps
            ? state.initialInvestment * Math.pow(state.rate, state.maturesSteps)
            : state.initialInvestment
    const shouldForceSell: TermDeposit['shouldForceSell'] = state =>
        state.currentStep >= state.maturesSteps ? 'Your term deposit has matured' : undefined

    return {
        type: 'termDeposit',
        name: 'Term deposit',
        initialInvestment,
        maturesSteps,
        currentStep: 0,
        rate,
        step,
        sellValue,
        shouldForceSell,
    }
}

interface LoanShark extends InvestmentCommon {
    type: 'loanShark'
    borrowedAmount: number
    rate: number
    step(state: LoanShark): LoanShark
    sellValue(state: LoanShark): number
    // Returns a reason or undefined if force sell not required
    shouldForceSell(state: LoanShark): string | undefined
}

export function startLoanShark(borrowedAmount: number, rate: number): LoanShark {
    const sellValue: LoanShark['sellValue'] = state => -state.borrowedAmount * Math.pow(state.rate, state.currentStep)
    const shouldForceSell: LoanShark['shouldForceSell'] = state => undefined

    return {
        type: 'loanShark',
        name: 'Loan shark',
        borrowedAmount,
        currentStep: 0,
        rate,
        step,
        sellValue,
        shouldForceSell,
    }
}

interface Fund extends InvestmentCommon {
    type: 'instrument'
    instrument: Instrument
    value: number
    step(state: Fund): Fund
    sellValue(state: Fund): number
    // Returns a reason or undefined if force sell not required
    shouldForceSell(state: Fund): string | undefined
}

export function startFund(amountToInvest: number, instrument: Instrument, investmentTurn: number): Fund {
    const sellValue: Fund['sellValue'] = state => state.value
    const shouldForceSell: Fund['shouldForceSell'] = state => undefined
    const step = <T extends Fund>(state: T) =>
        produce(state, state => {
            state.currentStep++
            console.log('value:', state.value)
            console.log('multiplier:', instrument.priceHistory[state.currentStep])
            state.value *= instrument.priceHistory[state.currentStep]
            console.log('new value:', state.value)
        })

    return {
        type: 'instrument',
        name: instrument.name,
        instrument,
        value: amountToInvest,
        currentStep: investmentTurn,
        step,
        sellValue,
        shouldForceSell,
    }
}

export type Investment = TermDeposit | LoanShark | Fund
