import {createContext, Dispatch, useContext} from 'react'
import {produce} from 'immer'
import {InventoryItem} from './story/inventoryItems'
import {createAction, ActionsUnion} from './hackyRedux'
import {Investment, startTermDeposit, startLoanShark, startFund} from './story/investments'
import {narratives} from './story/narratives'
import {randomIndex} from './helpers/random-index'
import {instruments} from './story/instruments'

export interface State {
    gameState: 'splash' | 'playing' | 'victory' | 'failure'
    currentTurn: number
    inventory: InventoryItem[]
    incomePerTurn: number
    wealth: {
        cash: number
        investments: Investment[]
    }
    narrativeIndex: number
}

export const initialState: State = {
    gameState: 'splash',
    currentTurn: 1,
    inventory: [],
    incomePerTurn: 30,
    wealth: {
        cash: 5,
        investments: [],
    },
    narrativeIndex: 0,
}

export const actions = {
    play: () => createAction('play'),
    nextTurn: () => createAction('nextTurn'),
    addCash: (amount: number) => createAction('addCash', {amount}),
    startTermDeposit: (percentage: number, matureSteps: number, rate: number) =>
        createAction('startTermDeposit', {percentage, matureSteps, rate}),
    startLoanShark: (amount: number, rate: number) => createAction('startLoanShark', {amount, rate}),
    sellInvestment: (index: number) => createAction('sellInvestment', {index}),
    goToSchool: () => createAction('goToSchool'),
    startInvestment: (percentage: number, symbol: keyof typeof instruments) =>
        createAction('startInvestment', {percentage, symbol}),
    setNarrative: (index: number) => createAction('setNarrative', {index}),
}

export const reducer = (state: State, action: ActionsUnion<typeof actions>): State => {
    switch (action.type) {
        case 'play':
            return {
                ...initialState,
                gameState: 'playing',
            }
        case 'nextTurn':
            return produce(state, state => {
                state.currentTurn += 1
                if (state.wealth.cash < 0) {
                    state.gameState = 'failure'
                }
                if (state.wealth.cash > 500000) {
                    state.gameState = 'victory'
                }
                state.wealth.cash += state.incomePerTurn
                state.wealth.investments = state.wealth.investments.map(investment => investment.step(investment))
                for (const i in state.wealth.investments) {
                    const investment = state.wealth.investments[i]
                    const shouldForceSell = investment.shouldForceSell(investment)
                    if (shouldForceSell) {
                        state.wealth.cash += investment.sellValue(investment)
                        delete state.wealth.investments[i]
                    }
                }

                do {
                    state.narrativeIndex = randomIndex(narratives, state.narrativeIndex)
                } while (state.narrativeIndex === 0) // The first one is the starting narrative which we don't want to see again
            })
        case 'addCash':
            return produce(state, state => {
                const {amount} = action.payload
                state.wealth.cash += amount
            })
        case 'goToSchool':
            console.log(action)
            const newState = produce(state, state => {
                state.wealth.cash -= 200
                state.incomePerTurn += 20
            })
            console.log(state.wealth.cash)
            console.log(newState.wealth.cash)
            return newState
        case 'startTermDeposit':
            return produce(state, state => {
                const {percentage, matureSteps, rate} = action.payload
                const amount = state.wealth.cash * percentage
                state.wealth.cash -= amount
                state.wealth.investments.push(startTermDeposit(amount, matureSteps, 1 + rate))
            })
        case 'startLoanShark':
            return produce(state, state => {
                const {amount, rate} = action.payload
                state.wealth.cash += amount
                state.wealth.investments.push(startLoanShark(amount, 1 + rate))
            })
        case 'startInvestment':
            return produce(state, state => {
                const {percentage, symbol} = action.payload
                const amount = state.wealth.cash * percentage
                const instrument = instruments[symbol]
                state.wealth.cash -= amount
                state.wealth.investments.push(startFund(amount, instrument, state.currentTurn))
            })
        case 'sellInvestment':
            return produce(state, state => {
                const {index} = action.payload
                const investment = state.wealth.investments[index]
                state.wealth.cash += investment.sellValue(investment)
                delete state.wealth.investments[index]
            })
        case 'setNarrative':
            return produce(state, state => {
                const {index} = action.payload
                state.narrativeIndex = index
            })
    }
}

export const StoreContext = createContext<{
    state: State
    dispatch: Dispatch<ActionsUnion<typeof actions>>
}>({
    state: initialState,
    dispatch: () => undefined,
})

export const withStore = () => useContext(StoreContext)
