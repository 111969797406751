import React, {useCallback} from 'react'
import {withStore, actions} from './store'
import {Wealth} from './Wealth'
import {NarrativeAndDecision} from './story/NarrativeAndDecision'
import {Inventory} from './Inventory'

export const Game: React.FC = () => {
    const {state, dispatch} = withStore()

    const nextTurn = useCallback(() => {
        dispatch(actions.nextTurn())
    }, [state])
    const moreMoney = useCallback(() => {
        dispatch(actions.addCash(5))
    }, [state])
    const termDeposit = useCallback(() => {
        dispatch(actions.startTermDeposit(state.wealth.cash, 10, 0.01))
    }, [state])
    const borrowMoney = useCallback(() => {
        dispatch(actions.startLoanShark(500, 0.05))
    }, [state])
    const investInHighTimes = useCallback(() => {
        dispatch(actions.startInvestment(1, 'HTF'))
    }, [state])

    return (
        <div className="game">
            <div className="title">
                <h1>InvestQuest™</h1>
            </div>
            <div className="narrative">
                <div className="story">
                    <NarrativeAndDecision />
                </div>
                <div className={`narrative-image narrative-image-${state.narrativeIndex}`}></div>
            </div>
            <button onClick={nextTurn}>nextTurn</button>
            <button onClick={moreMoney}>More money!</button>
            <button onClick={termDeposit}>Start term deposit</button>
            <button onClick={borrowMoney}>Borrow money</button>
            <button onClick={investInHighTimes}>Invest in high times</button>
            <button
                onClick={() => {
                    const index = prompt('What narrative number?')
                    dispatch(actions.setNarrative(Number(index)))
                }}
            >
                Jump to narrative
            </button>
            <Wealth />
            <Inventory />
        </div>
    )
}
