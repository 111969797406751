import React from 'react'
import ReactDOM from 'react-dom'
import {SplashScreen} from './Splash'
import {Game} from './Game'
import {StoreContext, reducer, initialState} from './store'
import {Failure, Victory} from './EndGame'

const App: React.FC = () => {
    const [state, dispatch] = React.useReducer(reducer, initialState)

    return (
        <StoreContext.Provider value={{state, dispatch}}>
            {state.gameState === 'splash' && <SplashScreen />}
            {state.gameState === 'failure' && <Failure />}
            {state.gameState === 'victory' && <Victory />}
            {state.gameState === 'playing' && <Game />}
        </StoreContext.Provider>
    )
}

ReactDOM.createRoot(document.getElementById('investquest')!).render(<App />)
