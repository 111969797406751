import React, {useCallback, ReactNode} from 'react'
import {withStore, State, actions} from '../store'
import {narratives} from './narratives'
import {investmentDecisions, Decision, LoanSharkDecision, TermDepositDecision} from './decisions'
import ReactMarkdown from 'react-markdown'
import {randomIndex} from '../helpers/random-index'
import {instruments} from './instruments'

export const NarrativeAndDecision: React.FC = () => {
    const {state} = withStore()
    const narrative = narratives[state.narrativeIndex]

    let decision = narrative.decision || investmentDecisions[randomIndex(investmentDecisions)]
    return (
        <>
            <div>
                <ReactMarkdown
                    source={narrative.body}
                    allowedTypes={['text', 'paragraph', 'strong', 'list', 'listItem', 'thematicBreak', 'heading']}
                />
                <hr />
            </div>
            <DecisionComp decision={decision} />
        </>
    )
}

const DecisionComp: React.FC<{decision: Decision}> = ({decision}) => {
    switch (decision.type) {
        case 'nothing':
            return <NothingDecision decision={decision} />
        case 'investment':
            return <InvestmentDecision decision={decision} />
        case 'term-deposit':
            return <TermDepositDecision decision={decision} />
        case 'school':
            return <SchoolDecision decision={decision} />
        case 'loan-shark':
            return <LoanSharkDecision decision={decision} />
    }
}

const NothingDecision: React.FC<{decision: Decision}> = ({decision}) => {
    const {state, dispatch} = withStore()

    const nextTurn = useCallback(() => {
        dispatch(actions.nextTurn())
    }, [state])

    return (
        <div>
            <ReactMarkdown
                source={decision.body}
                allowedTypes={['text', 'paragraph', 'strong', 'list', 'listItem', 'thematicBreak', 'heading']}
            />
            <button onClick={nextTurn}>Ok</button>
        </div>
    )
}

const InvestmentDecision: React.FC<{decision: Decision}> = ({decision}) => {
    const {state, dispatch} = withStore()

    const instrumentKeys = Object.keys(instruments)
    const symbol = instrumentKeys[randomIndex(instrumentKeys)]
    const instrument = instruments[symbol]
    const body = decision.body.replace(/<instrument>/g, `${instrument.name} (${symbol})`)

    const nextTurn = useCallback(() => {
        dispatch(actions.nextTurn())
    }, [state])
    const invest = useCallback(
        (percentage: number) => {
            dispatch(actions.startInvestment(percentage, symbol))
            dispatch(actions.nextTurn())
        },
        [state, symbol],
    )

    const invest100 = invest.bind(null, 1)
    const invest50 = invest.bind(null, 0.5)
    const invest25 = invest.bind(null, 0.25)

    return (
        <div>
            <ReactMarkdown
                source={body}
                allowedTypes={['text', 'paragraph', 'strong', 'list', 'listItem', 'thematicBreak', 'heading']}
            />
            <button onClick={invest100}>Invest 100%</button>
            <button onClick={invest50}>Invest 50%</button>
            <button onClick={invest25}>Invest 25%</button>
            <button onClick={nextTurn}>Nada</button>
        </div>
    )
}

const TermDepositDecision: React.FC<{decision: TermDepositDecision}> = ({decision}) => {
    const {state, dispatch} = withStore()

    const nextTurn = useCallback(() => {
        dispatch(actions.nextTurn())
    }, [state])
    const invest = useCallback(
        (percentage: number) => {
            dispatch(actions.startTermDeposit(percentage, decision.matureSteps, decision.rate))
            dispatch(actions.nextTurn())
        },
        [state],
    )

    const invest100 = invest.bind(null, 1)
    const invest50 = invest.bind(null, 0.5)
    const invest25 = invest.bind(null, 0.25)

    return (
        <div>
            <button onClick={invest100}>Invest 100%</button>
            <button onClick={invest50}>Invest 50%</button>
            <button onClick={invest25}>Invest 25%</button>
            <button onClick={nextTurn}>Nada</button>
        </div>
    )
}

const LoanSharkDecision: React.FC<{decision: LoanSharkDecision}> = ({decision}) => {
    const {state, dispatch} = withStore()

    const nextTurn = useCallback(() => {
        dispatch(actions.nextTurn())
    }, [state])
    const borrow = useCallback(
        (amount: number) => {
            dispatch(actions.startLoanShark(amount, decision.rate))
            dispatch(actions.nextTurn())
        },
        [state, decision.rate],
    )

    const borrow500 = borrow.bind(null, 500)
    const borrow2000 = borrow.bind(null, 2000)
    const borrow8000 = borrow.bind(null, 8000)

    return (
        <div>
            <button onClick={borrow500}>Borrow $500</button>
            <button onClick={borrow2000}>Borrow $2,000</button>
            <button onClick={borrow8000}>Borrow $8,000</button>
            <button onClick={nextTurn}>Buzz off</button>
        </div>
    )
}

const SchoolDecision: React.FC<{decision: Decision}> = ({decision}) => {
    const {state, dispatch} = withStore()

    const no = useCallback(() => {
        dispatch(actions.nextTurn())
    }, [state])
    const yes = useCallback(() => {
        dispatch(actions.goToSchool())
        dispatch(actions.nextTurn())
    }, [state])

    return (
        <div>
            <button onClick={no}>Don't go to school</button>
            <button onClick={yes}>Go to school</button>
        </div>
    )
}
